import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Title from "../../../../components/Layout/Title";
import { getElementsAdmin } from "../../../../database/API";
import theme from "../../../../theme";
import { useNavigate } from "react-router";
import CalendariDialogAdd from "./CalendariDialogAdd";
import interactionPlugin from "@fullcalendar/interaction";
import { teDisponibilitat } from "../../../../data/Utils";
import moment from "moment";
import KPICalendari from "../../../../components/KPICalendari";
import LoadingOverlay from "../../../../components/Layout/LoadingOverlay";
import CalendariDialogMesAdd from "./CalendariDialogMesAdd";

const CalendariAdminEAV = () => {
	const classes = useStyles();
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [dateSel, setDateSel] = useState();
	const [open, setOpen] = useState();
	const [openMes, setOpenMes] = useState(false);
	const [update, setUpdate] = useState(0);
	const [calendari, setCalendari] = useState([]);
	const [actualDate, setActualDate] = useState(moment());

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const reserves = await getElementsAdmin("entradesCal");

			let events = [];
			reserves.result.map((item) =>
				events.push({
					title:
						item.id +
						" - " +
						item.nom +
						" (" +
						item.persones.length +
						" pax.)",
					start: item.data,
					color: theme.palette.background.main,
					id: item.id,
				})
			);
			setEvents(events);
			setLoading(false);
		};

		get();
	}, []);

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const calendaris = await getElementsAdmin("calendaris");
			setCalendari(calendaris.result);
			setLoading(false);
		};
		get();
	}, [update]);

	function onClickEvent(e) {
		navigate("/admin/entradesEAV/" + e.event.id);
	}

	function dateClick(e) {
		setDateSel(e["dateStr"]);
		setOpen(true);
	}

	const renderDayContent = (day) => {
		return (
			<Tooltip title={"dfsf"}>
				<Box>
					<Box zIndex={10}>
						<Typography fontSize={14}>
							{day.dayNumberText}
						</Typography>
					</Box>
					<Box className={classes.iconCalendar}>
						{teDisponibilitat(moment(day.date), calendari)}
					</Box>
				</Box>
			</Tooltip>
		);
	};

	const getCurrentMonth = (arg) => {
		const midDate = new Date((arg.start.getTime() + arg.end.getTime()) / 2);
		let actual = moment();
		actual.set("month", midDate.getMonth());
		actual.set("year", midDate.getFullYear());
		setActualDate(actual);
	};

	return (
		<Box className={classes.container}>
			<Title title="Calendari - Espai Actiu" />
			<KPICalendari />
			<LoadingOverlay loading={loading}>
				<Box p={2}>
					<div className={classes.calendar}>
						<FullCalendar
							datesSet={(arg) => getCurrentMonth(arg)}
							plugins={[dayGridPlugin, interactionPlugin]}
							initialView="dayGridMonth"
							events={events}
							locale={"ca"}
							height={window.innerHeight - 240}
							firstDay={1}
							eventClick={onClickEvent}
							dayCellContent={renderDayContent}
							dateClick={dateClick}
							buttonText={{ today: "avui" }}
							displayEventTime={false}
							headerToolbar={{
								left: "prev,next today",
								center: "title",
								right: "myCustomButton",
							}}
							customButtons={{
								myCustomButton: {
									text: "Canviar tot el mes",
									click: function () {
										setOpenMes(true);
									},
								},
							}}
						/>
					</div>
					<CalendariDialogAdd
						setOpen={setOpen}
						open={open}
						setUpdate={setUpdate}
						dateSel={dateSel}
						setLoading={setLoading}
						calendari={calendari}
					/>
					<CalendariDialogMesAdd
						setOpen={setOpenMes}
						open={openMes}
						setUpdate={setUpdate}
						dateSel={actualDate}
						setLoading={setLoading}
					/>
				</Box>
			</LoadingOverlay>
		</Box>
	);
};

export default CalendariAdminEAV;

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	block: {
		boxShadow: "#00000020 1px 3px 20px 1px ",
		borderRadius: 30,
		padding: 30,
		zIndex: 0,
		marginBottom: 80,
		backgroundColor: "white",
		overflow: "hidden",
		position: "relative",
	},
	calendar: {
		fontFamily: "Montserrat",
		color: theme.palette.text.primary,
		"& .fc-button-primary": {
			backgroundColor: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
			"&:hover": {
				backgroundColor: theme.palette.primary.hover,
				borderColor: theme.palette.primary.hover,
			},
		},
		"& .fc-scroller-harness-liquid": {
			height: "100%",
		},
	},
	iconCalendar: {
		position: "absolute",
		top: 3,
		right: 30,
		width: 20,
		height: 20,
	},
}));
